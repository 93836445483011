import React, {useEffect} from "react";
import {
    Spacer
} from "@nextui-org/react";
import {
    getCurrentDate
} from "../inc/js/Helper"

export default function Footer() {
    const currentDate = getCurrentDate();
    const currentYear = new Date(currentDate).getFullYear();

    return(
        <div>
            <Spacer y={20} />
            <footer className="bg-gray-100 text-blue-500 py-6 sm:border-t-1 sm:border-r-1 sm:border-l-1 sm:border-black sm:rounded-t-md">
                <div className="mx-auto px-4">
                    <div className="flex justify-between items-center text-xs sm:text-sm">
                        <div className="max-w-32 sm:max-w-full">
                            <p>&copy; {currentYear} JudgeAutos.com. All rights reserved.</p>
                        </div>
                        <div className="flex space-x-4">
                            <a href="/PrivacyPolicy" className="hover:underline">Privacy Policy</a>
                            <a href="/Contact" className="hover:underline">Contact Us</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}