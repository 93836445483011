import React, {useEffect} from "react";
import {
    Spacer
} from "@nextui-org/react";

export default function About() {
    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">About</h1>
            <Spacer y={10} />
            <div className="flex-col text-base sm:text-xl mx-auto sm:w-1/2 text-left">
                <p>Beginning in 2021, JudgeAutos was developed with a single goal in mind: to simplify the registration and judging process at events.</p> <br />
                <p>JudgeAutos offers a straightforward solution for registering cars, and judging efficiently with a user-friendly interface.</p> <br />
                <p>We prioritize functionality and ease of use, ensuring that events can effortlessly manage registrations and judges can swiftly evaluate vehicles. With JudgeAutos, the focus remains on simplifing the processes, allowing both participants and organizers to devote more time to enjoying the event itself.</p> <br />
                <p>Experience the convenience of JudgeAutos and let us simplify your car show experience, one registration at a time.</p>
            </div>
        </div>
    );
}