import React, {
    useEffect,
    useRef,
    useState
} from "react";
import { 
    Form,
    useLocation,
    useNavigate
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import {
    PlusCircle_6_Blue,
    ShareIcon_6_Blue
} from "../inc/images/svgs";
import {
    getCurrentDate
} from "../inc/js/Helper"
import swal from "sweetalert2";
import {
    Button,
    Divider,
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
    Input,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Spacer,
    Tab,
    Tabs,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell
} from "@nextui-org/react";

export default function Event(props) {
    useEffect(() => {
        if (props.userInformation.loggedIn === false) {
            document.location.href = "/Login";
        }
    }, [props]);

    useEffect(() => {
        localStorage.setItem('eventId', eventId);

        Ajax.request({
            url:'/JudgeAutos/readEventDetails?eventId=' + eventId,
            success:function(reply) {
                if(reply.data) {
                    setEventDetails(reply.data);
                    setEventName(reply.data.eventData.find(name => name.eventName).eventName);
                    setEventStatus(reply.data.eventData.find(status => status.status).status);
                    setClassValue(reply.data.classes.length + 1);
                    setJudgeForm(reply.data.eventData.find(form => form.judgeFormId).judgeFormId);
                    setRegistrationForm(reply.data.eventData.find(form => form.registrationFormId).registrationFormId);
                    setClassList(reply?.data?.eventData?.find(name => name.classListName)?.classListName ?? 'noClassList');
                }
            },
            failure:function(reply) {
                swal.fire({
                    title: "Oops!",
                    text: reply,
                    icon: "error",
                    didClose: () => {
                        document.location.href = "/Events"
                    }
                })
            }
        });
    }, []);

    const location = useLocation();
    const navigate = useNavigate();
    const eventId = location.state?.eventId || localStorage.getItem('eventId');
    
    const [classValue, setClassValue] = useState();
    const [eventDetails, setEventDetails] = useState([]);
    const [eventDetailsUpdate, setEventDetailsUpdate] = useState(false);
    const [eventName, setEventName] = useState(null);
    const [eventStatus, setEventStatus] = useState(null);
    const [classList, setClassList] = useState();
    const [judgeForm, setJudgeForm] = useState();
    const [registrationForm, setRegistrationForm] = useState();

    function addUser(type) {
        const userForm = {
            eventId:eventId,
            type:type,
            [`${type}Name`]:document.getElementById(`${type}Name`).value,
            [`${type}Number`]:document.getElementById(`${type}Number`).value,
            [`${type}Email`]:document.getElementById(`${type}Email`).value,
        };

        if (eventDetails[`${type}s`].length < eventDetails.eventData[0].registrarLimit) {
            if (!userForm[`${type}Name`] || !userForm[`${type}Number`]) {
                swal.fire({
                    title: "Missing fields!",
                    html: `Please enter a <strong>name</strong> and a <strong>number</strong>`,
                    icon: "error",
                    timer: 1500
                });
                return;
            } else if (userForm[`${type}Number`].replace(/-/g, '').length !== 10) {
                swal.fire({
                    title: "Invalid Phone Number",
                    html: `Please enter a 10 digit phone number`,
                    icon: "error",
                    timer: 1500
                });
                return;
            } else {
                Ajax.request({
                    url:"/JudgeAutos/addUser",
                    jsonData: userForm,
                    success:function(reply) {
                        swal.fire({
                            title:`Added ${type}`,
                            text:`${userForm[`${type}Name`]} has been added!`,
                            icon:"success",
                            timer: 1500,
                            didClose: () => {
                                window.location.reload();
                            }
                        })
                    }
                });
            }
        } else {
            swal.fire({
                title: "Oops!",
                text: `You have reached your ${type} limit`,
                icon: "error"
            });
        }
    }

    const viewResults = () => {
        navigate('/Results', {
            state: {
                eventId,
                eventName,
                eventStatus
            } 
        });
    };

    function textInvite(contactName, contactNumber, qrCode, type) {
        const contactForm = {
            name: contactName,
            contactNumber: contactNumber,
            qrCode: qrCode,
            eventName: eventName,
            type: type
        }

        Ajax.request({
            url:"/JudgeAutos/textInvite",
            jsonData: contactForm,
            success:function() {
                swal.fire({
                    title: "Sent!",
                    text: `An invite has been sent to ${contactForm.name}`,
                    icon: "success"
                });
            }
        });
    }

    function updateEvent() {
        const currentDate = getCurrentDate(); 
        const updateForm = {
            eventId: eventDetails.eventData.find(id => id.eventId).eventId,
            eventName:document.getElementById("eventName").value,
            eventLocation:document.getElementById("eventLocation").value,
            eventDate:document.getElementById("eventDate").value,
            rainoutDate:document.getElementById("rainoutDate").value,
            registrationForm: registrationForm,
            registrationFormId: eventDetails.registrationForms.find(form => form.registrationFormId == registrationForm).registrationFormId,
            judgeForm: judgeForm,
            judgeFormId: eventDetails.judgeForms.find(form => form.judgeFormId == judgeForm).judgeFormId,
            classListId: eventDetails?.classLists?.find(id => id.classListName === classList)?.classListId ?? null
        };
        
        if (updateForm.eventDate === currentDate) {
            updateForm.status = "active"
        } else if (updateForm.eventDate > currentDate) {
            updateForm.status = "upcoming"
        } else {
            swal.fire({
                title: "Invalid event date",
                text: "The event date cannot be in the past!",
                icon: "error",
                timer: 1500
            })
            return;
        }

        if (updateForm.rainoutDate === "") {
            updateForm.rainoutDate = null;
        }

        Ajax.request({
            url:"/JudgeAutos/updateEvent",
            jsonData:updateForm,
            success:function(reply) {
                swal.fire({
                    title: "Success!",
                    text: "Event Updated",
                    icon: "success",
                    timer: 1500,
                    didClose: () => {
                        window.location.reload();
                    }
                });
            }
        });
    }

    // WORK AROUND FOR NEXTUI ISSUE 1968
    const dummyKeyboardDelegate = Object.fromEntries(
        [
          "getKeyBelow",
          "getKeyAbove",
          "getKeyLeftOf",
          "getKeyRightOf",
          "getKeyPageBelow",
          "getKeyPageAbove",
          "getFirstKey",
          "getLastKey",
          // HAVE TO ignore this one
          // "getKeyForSearch"
        ].map((name) => [name, () => null]),
      );

    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">{eventName}</h1>
            <Spacer y={1} />
            <p>
                ({eventStatus})
            </p>
            {eventStatus === "completed" || eventStatus === "active" ? (
                <div>
                    <Spacer y={5} />
                    <div className="flex mx-auto justify-center h-5 items-center space-x-4">
                        <Divider orientation="vertical"/>
                        <Button 
                            variant="light"
                            color="primary"
                            size="sm"
                            onClick={viewResults}
                        >
                            <div className="text-lg">
                                Results
                            </div>
                        </Button>
                        <Divider orientation="vertical"/>
                    </div>
                    <Spacer y={4} />
                </div>
            ): (
                <Spacer y={9} />
            )}
            {eventDetails.eventData && (
                <Tabs>
                    {eventDetails.formData?.length > 0 && (
                        <Tab key="registrations" title="Registrations" aria-label="Registrations tab" >
                            <Table isStriped aria-label="Event registrations">
                                <TableHeader>
                                    {eventDetails.formData.filter(
                                        data => data.attribute !== "Class" ||
                                        (data.attribute === "Class" && classList !== "noClassList")).map((data, index) => (
                                        <TableColumn key={index}>{data.attribute}</TableColumn>
                                    ))};
                                    <TableColumn>Score</TableColumn>
                                </TableHeader>
                                <TableBody>
                                    {eventDetails.formSubmissions.map((data, index) => (
                                        <TableRow key={index}>
                                            {data.map((cellData, innerIndex) => (
                                                <TableCell key={innerIndex}>{cellData}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Tab>
                    )}
                    {/* {(eventDetails?.eventData?.find(limit => limit.classLimit)?.classLimit ?? 0) > 0  && ( */}
                    {classList !== "noClassList"  && (
                        <Tab key="classes" title="Classes" aria-label="Classes tab" >
                                <Table isStriped aria-label="Clases" keyboardDelegate={dummyKeyboardDelegate}>
                                    <TableHeader>
                                        <TableColumn>Class Number</TableColumn>
                                        <TableColumn>Class Name</TableColumn>
                                        <TableColumn>Registrations</TableColumn>
                                    </TableHeader>
                                    <TableBody>
                                        {eventDetails.classes.map((data, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{data.classNumber}</TableCell>
                                                <TableCell>{data.className}</TableCell>
                                                <TableCell>0</TableCell>
                                                {/* <TableCell>{eventDetails.registrations.filter(registration => registration.class === data.classNumber).length}</TableCell> */}
                                            </TableRow>
                                        ))};
                                    </TableBody>
                                </Table>
                        </Tab>
                    )}
                    <Tab key="registrars" title="Registrars" aria-label="Registrars tab">
                        <Table isStriped aria-label="Registrars" keyboardDelegate={dummyKeyboardDelegate}>
                            <TableHeader>
                                <TableColumn>Name</TableColumn>
                                <TableColumn>Number</TableColumn>
                                <TableColumn>Email</TableColumn>
                                <TableColumn>Cars Registered</TableColumn>
                                <TableColumn>Invite</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {eventDetails.registrars.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{data.name}</TableCell>
                                        <TableCell>{data.phoneNumber}</TableCell>
                                        <TableCell>{data.email}</TableCell>
                                        <TableCell>{data.carsRegistered}</TableCell>                                        
                                        <TableCell onClick={() => textInvite(data.name, data.phoneNumber, data.qrCode, 'registrar')} style={{ cursor: 'pointer'}}>
                                            <ShareIcon_6_Blue />
                                        </TableCell>
                                    </TableRow>
                                ))};
                                {eventDetails.registrars.length < eventDetails.eventData[0].registrarLimit && (
                                    <TableRow key="addRegistrarRow">
                                        <TableCell>
                                            <Input
                                                id="registrarName"
                                                placeholder="Add registrar!"
                                                type="text"
                                                color="primary"
                                                variant="underlined"
                                                classNames={{input: "text-center", inputWrapper: ["border-0", "shadow-none"]}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Input
                                                id="registrarNumber"
                                                placeholder="-"
                                                type="tel"
                                                color="primary"
                                                variant="underlined"
                                                classNames={{input: "text-center", inputWrapper: ["border-0", "shadow-none"]}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Input
                                                id="registrarEmail"
                                                placeholder="-"
                                                type="email"
                                                color="primary"
                                                variant="underlined"
                                                classNames={{input: "text-center", inputWrapper: ["border-0", "shadow-none"]}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            -
                                        </TableCell>
                                        <TableCell onClick={() => addUser("registrar")} style={{ cursor: 'pointer'}}>
                                            <PlusCircle_6_Blue />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Tab>
                    <Tab key="judges" title="Judges" aria-label="Judges tab">
                        <Table isStriped aria-label="Judges" keyboardDelegate={dummyKeyboardDelegate}>
                            <TableHeader>
                                <TableColumn>Name</TableColumn>
                                <TableColumn>Number</TableColumn>
                                <TableColumn>Email</TableColumn>
                                <TableColumn>Cars Judged</TableColumn>
                                <TableColumn>Invite</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {eventDetails.judges.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{data.name}</TableCell>
                                        <TableCell>{data.phoneNumber}</TableCell>
                                        <TableCell>{data.email}</TableCell>
                                        <TableCell>{data.carsJudged}</TableCell>
                                        <TableCell onClick={() => textInvite(data.name, data.phoneNumber, data.qrCode, 'judge')} style={{ cursor: 'pointer'}}>
                                            <ShareIcon_6_Blue />
                                        </TableCell>
                                    </TableRow>
                                ))};
                                {eventDetails.judges.length < eventDetails.eventData[0].judgeLimit && (
                                    <TableRow key="addJudgeRow">
                                        <TableCell>
                                            <Input
                                                id="judgeName"
                                                placeholder="Add judge!"
                                                type="text"
                                                color="primary"
                                                variant="underlined"
                                                classNames={{input: "text-center", inputWrapper: ["border-0", "shadow-none"]}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Input
                                                id="judgeNumber"
                                                placeholder="-"
                                                type="tel"
                                                color="primary"
                                                variant="underlined"
                                                classNames={{input: "text-center", inputWrapper: ["border-0", "shadow-none"]}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Input
                                                id="judgeEmail"
                                                placeholder="-"
                                                type="email"
                                                color="primary"
                                                variant="underlined"
                                                classNames={{input: "text-center", inputWrapper: ["border-0", "shadow-none"]}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            -
                                        </TableCell>
                                        <TableCell onClick={() => addUser("judge")} style={{ cursor: 'pointer'}}>
                                            <PlusCircle_6_Blue />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Tab>
                    <Tab key="eventDetails" title="Event Details" aria-label="Event Details Tab">
                        {eventDetails.eventData?.length > 0 && eventDetails.eventData.map((data, index) => (
                            <Form key={index}>                
                                <Input
                                    key={data.eventId}
                                    isReadOnly
                                    id="eventId"
                                    type="number"
                                    label="Event Id"
                                    variant="flat"
                                    defaultValue={data.eventId}
                                />
                                <Spacer y={1} />
                                <Popover placement="bottom" offset={10} showArrow>
                                    <PopoverTrigger>
                                        <Input
                                            isReadOnly
                                            key={data.status}
                                            id="eventStatus"
                                            type="text"
                                            label="Status"
                                            variant="flat"
                                            defaultValue={`${data.status.charAt(0).toUpperCase() + data.status.slice(1)} Event`}
                                            classNames={{
                                            input: "text-left",
                                            }}
                                        />
                                    </PopoverTrigger>
                                    <PopoverContent>
                                    <div className="px-1 py-2">
                                        <div className="text-small font-bold">NOTICE</div>
                                        <div className="text-tiny">To change the status, update the Event Date</div>
                                    </div>
                                    </PopoverContent>
                                </Popover>
                                <Spacer y={1} />
                                <Input
                                    readOnly={eventDetails.eventData.find(value => value.status).status === "completed" ? true : false}
                                    key={data.eventName}
                                    id="eventName"
                                    type="text"
                                    label="Event Name"
                                    variant={eventDetails.eventData.find(value => value.status).status === "completed" ? "flat" : "bordered"}
                                    defaultValue={data.eventName}
                                    onValueChange={() => {setEventDetailsUpdate(true)}}
                                />
                                <Spacer y={1} />
                                <Input
                                    readOnly={eventDetails.eventData.find(value => value.status).status === "completed" ? true : false}
                                    key={`event-${data.eventDate}`}
                                    id="eventDate"
                                    type="date"
                                    label="Event Date"
                                    variant={eventDetails.eventData.find(value => value.status).status === "completed" ? "flat" : "bordered"}
                                    defaultValue={`${data.eventDate.split(" ")[0]}`}
                                    onValueChange={() => {setEventDetailsUpdate(true)}}
                                />
                                <Spacer y={1} />
                                <Input
                                    readOnly={eventDetails.eventData.find(value => value.status).status === "completed" ? true : false}
                                    key={`rainout-${data.rainoutDate}`}
                                    id="rainoutDate"
                                    type="date"
                                    label="Rainout Date"
                                    variant={eventDetails.eventData.find(value => value.status).status === "completed" ? "flat" : "bordered"}
                                    defaultValue={data.rainoutDate ? data.rainoutDate.split(" ")[0] : ""}
                                    onValueChange={() => {setEventDetailsUpdate(true)}}
                                />
                                <Spacer y={1} />
                                <Input
                                    readOnly={eventDetails.eventData.find(value => value.status).status === "completed" ? true : false}
                                    key={data.eventLocation}
                                    id="eventLocation"
                                    type="text"
                                    label="Event Location"
                                    variant={eventDetails.eventData.find(value => value.status).status === "completed" ? "flat" : "bordered"}
                                    defaultValue={data.eventLocation}
                                    onValueChange={() => {setEventDetailsUpdate(true)}}
                                />
                                <Spacer y={1} />
                                <div className="grid grid-cols-2 gap-2 place-content-center">
                                        <Dropdown>
                                            <DropdownTrigger>
                                                <Button
                                                    variant={
                                                        eventDetails.eventData.find(value => value.status).status === "completed" ||
                                                        eventDetails.eventData.find(value => value.forms).forms !== "custom" ? "solid" : "bordered"
                                                    }
                                                >
                                                    Registration Forms
                                                </Button>
                                            </DropdownTrigger>
                                                <DropdownMenu
                                                    disabled={
                                                        eventDetails.eventData.find(value => value.forms).forms === "custom" && 
                                                        eventDetails.eventData.find(value => value.status).status !== "completed" ? false : true
                                                    }
                                                    disallowEmptySelection
                                                    aria-label="Registration Form Dropdown Menu"
                                                    selectionMode="single"
                                                    selectedKeys={new Set([registrationForm])}
                                                    onSelectionChange={
                                                        eventDetails.eventData.find(value => value.forms).forms === "custom" && 
                                                        eventDetails.eventData.find(value => value.status).status !== "completed" && (
                                                            (selected) => {
                                                                const selectedKey = Array.from(selected)[0];
                                                                setRegistrationForm(selectedKey);
                                                                setEventDetailsUpdate(true)
                                                            }
                                                        )
                                                    }
                                                    
                                                >
                                                    {eventDetails.registrationForms.map((data, index) => (
                                                        <DropdownItem key={data.registrationFormId}>{data.formName}</DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                        </Dropdown>
                                        <Dropdown>
                                            <DropdownTrigger>
                                                <Button
                                                    variant={
                                                        eventDetails.eventData.find(value => value.status).status === "completed" ||
                                                        eventDetails.eventData.find(value => value.forms).forms !== "custom" ? "solid" : "bordered"
                                                    }
                                                >
                                                    Judge Forms
                                                </Button>
                                            </DropdownTrigger>
                                                <DropdownMenu
                                                    disabled={
                                                        eventDetails.eventData.find(value => value.forms).forms === "custom" && 
                                                        eventDetails.eventData.find(value => value.status).status !== "completed" ? false : true
                                                    }
                                                    disallowEmptySelection
                                                    aria-label="Judge Form Dropdown Menu"
                                                    selectionMode="single"
                                                    selectedKeys={new Set([judgeForm])}
                                                    onSelectionChange={
                                                        eventDetails.eventData.find(value => value.forms).forms === "custom" && 
                                                        eventDetails.eventData.find(value => value.status).status !== "completed" && (
                                                            (selected) => {
                                                                const selectedKey = Array.from(selected)[0];
                                                                setJudgeForm(selectedKey);
                                                                setEventDetailsUpdate(true)
                                                            }
                                                        )
                                                    }
                                                >
                                                    {eventDetails.judgeForms.map((data, index) => (
                                                        <DropdownItem key={data.judgeFormId}>{data.formName}</DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                        </Dropdown>
                                        <Dropdown>
                                            <DropdownTrigger>
                                                <Button
                                                    variant={
                                                        eventDetails.eventData.find(value => value.status).status === "completed" ||
                                                        (eventDetails?.eventData?.find(limit => limit.classLimit)?.classLimit ?? 0) == 0 ? "solid" : "bordered"
                                                    }
                                                >
                                                    Class List
                                                </Button>
                                            </DropdownTrigger>
                                                <DropdownMenu
                                                    disabled={
                                                        eventDetails.eventData.find(value => value.status).status !== "completed" ? false : true
                                                    }
                                                    disallowEmptySelection
                                                    aria-label="Class List Dropdown Menu"
                                                    selectionMode="single"
                                                    selectedKeys={new Set([classList])}
                                                    onSelectionChange={
                                                        (eventDetails?.eventData?.find(value => value.classLimit)?.classLimit ?? 0) > 0 && (
                                                            (selected) => {
                                                                const selectedKey = Array.from(selected)[0];
                                                                setClassList(selectedKey);
                                                                setEventDetailsUpdate(true)
                                                            }
                                                        )
                                                    }
                                                >
                                                    <DropdownItem key="noClassList">None</DropdownItem>
                                                    {eventDetails.classLists.map((data, index) => (
                                                        <DropdownItem key={data.classListName}>{data.classListName}</DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                        </Dropdown>
                                </div>
                                <Spacer y={1} />
                                <Input
                                    isReadOnly
                                    id="eventQrCode"
                                    type="text"
                                    label="Link"
                                    variant="flat"
                                    defaultValue={`judgeautos.com/JudgeAutos/Events?code=${data.qrCode}`}
                                />
                                <Spacer y={1} />
                                <Input
                                    isReadOnly
                                    id="salesId"
                                    type="number"
                                    label="Sales Id"
                                    variant="flat"
                                    defaultValue={data.salesId}
                                />
                                <Spacer y={1} />
                                <Input
                                    isReadOnly
                                    id="productName"
                                    type="text"
                                    label="Product"
                                    variant="flat"
                                    defaultValue={data.productName}
                                />
                                <Spacer y={2} />
                                {
                                    eventDetails.eventData.find(value => value.status).status !== "completed" && 
                                    eventDetailsUpdate && (
                                    <Button
                                        onClick={updateEvent}
                                        color="primary"
                                    >
                                        Update
                                    </Button>
                                )}
                            </Form>
                        ))}
                    </Tab>
                </Tabs>
            )}
        </div>
    );
}