import React, {
    useEffect
} from "react";
import {
    Spacer
} from "@nextui-org/react";
import swal from "sweetalert2";

export default function Docs(props) {
    useEffect(() => {
        if (props.userInformation.loggedIn === false) {
            swal.fire({
                title: "Login required",
                html: "Please <a href='/Login' style='text-decoration:underline; color: blue;'>Login</a> to access our documents",
                icon: "info",
                didClose: () => {
                    document.location.href = "/Login";
                }
            })
        }
    }, [props]);

    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Documentation</h1>
            <Spacer y={10} />
            
        </div>
    );
}