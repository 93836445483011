import { Spacer } from "@nextui-org/react";
import React, {useEffect} from "react";
import Ajax from "../inc/js/Ajax";

export default function ContactUs() {
    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Contact</h1>
            <Spacer y={4} />
            <div className="flex-col mx-auto w-1/2 text-left">
                <p className="text-base">Something will go here</p>
            </div>
        </div>
    );
}