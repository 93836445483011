

export default function Error404() {
    return(
        <div>
            <h1>
                Ooops!  That page doesn't exist!
            </h1>
        </div>
    );
}