import {
    Spacer,
    Link
} from "@nextui-org/react";

export default function Verified() {

    return(
        <div className="flex-1 text-center">
            <h1 className="text-5xl">Email Verified!</h1>
            <Spacer y={10} />
            <p className="text-2xl">
                Thank you for confirming your email, you may now <Link className="text-2xl" href="/Login">login</Link>.
            </p>
        </div>
    );
}