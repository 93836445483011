import React, {
    useEffect,
    useState
} from "react";
import { 
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Image,
    Link,
    Spacer,
    
} from "@nextui-org/react";
import swal from 'sweetalert2';
import Ajax from "../inc/js/Ajax";

export default function Pricing(props) {
    useEffect(() => {
        console.log(props);
        Ajax.request({
            url:"/JudgeAutos/readPrices",
            success:function(reply) {
                setPrices(reply.data);
            }
        });
    }, [props]);

    const [prices, setPrices] = useState([]);

    function cardPressed(props, priceId) {
        if (priceId) {
            if (props.userInformation.loggedIn) {
                if (props.userInformation.stripeCustomerId && props.userInformation.stripeCustomerId !== "") {
                    document.location.href = "/JudgeAutos/createCheckoutSession?priceId=" + priceId;
                } else {
                    Ajax.request({
                        url:'/JudgeAutos/createStripeCustomerId',
                        jsonData: {
                            userId: props.userInformation.userId,
                            email: props.userInformation.email
                        },
                        success:function() {
                            document.location.href = "/JudgeAutos/createCheckoutSession?priceId=" + priceId;
                        }
                    })
                }
            } else {
                swal.fire({
                    title:"Lets get started!",
                    text:"Login or create an account to get started!",
                    icon: "success",
                    didClose: () => {
                        document.location.href = "/Login";
                    }
                })
            }
        } else {
            swal.fire({
                title:"Failed to get PriceId",
                text:"The page will reload.  Please try again!",
                icon: "error",
                didClose: () => {
                    document.location.href = "/Pricing";
                }
            })
        }
    }

    return (
        <div className="flex-grow flex flex-col text-center">
          <h1 className="text-5xl">Pricing</h1>
          <Spacer y={10} />
          <div className="grid gap-10 sm:gap-2 sm:flex sm:place-content-center">
            {prices.map((item, index) => (
              <Card
                isPressable
                shadow="sm"
                key={index}
                onPress={() => cardPressed(props, item.priceId)}
              >
                <CardHeader>
                  <h3 className="text-2xl text-center">{item.name}</h3>
                </CardHeader>
                <CardBody className="overflow-visible p-0">
                  <ul className="list-disc list-inside px-10">
                    {item.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </CardBody>
                <CardFooter className="text-3xl justify-center">
                  {props.userInformation.loggedIn ? (
                    <div>
                      <p className="text-default-500">${item.price}</p>
                      <p className="text-default-500">Buy now!</p>
                    </div>
                  ) : (
                    <div>
                      <p className="text-default-500">${item.price}</p>
                    </div>
                  )}
                </CardFooter>
              </Card>
            ))}
          </div>
        </div>
      );      
}