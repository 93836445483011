import React, {
    useEffect,
    useRef,
    useState
} from "react";
import {
    Form,
    useLocation,
    useNavigate
} from 'react-router-dom';
import Ajax from "../inc/js/Ajax";
import {
    PlusCircle_8_Blue,
    Trash_8_Red
} from "../inc/images/svgs";
import {
    Button,
    Divider,
    Input,
    Spacer,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
} from "@nextui-org/react";
import swal from "sweetalert2";

export default function List(props) {    
    useEffect(() => {
        if (props.userInformation.loggedIn === true) {
            const requestForm = {
                userId: props.userInformation.userId,
                listId: listId,
                listType: listType
            }

            Ajax.request({
                url:"/JudgeAutos/readList",
                jsonData: requestForm,
                success:function(reply) {
                    setListInfo(reply.data);
                },
                failures:function(reply) {
                    swal.fire({
                        title: "Failed to load list",
                        text: {reply},
                        icon: "error"
                    })
                }
            });
        } else if (props.userInformation.loggedIn === false) {
            document.location.href = "/Login";
        }
    }, [props]);

    const location = useLocation();
    const listId = location.state?.listId || localStorage.getItem('listId');
    const listType = location.state?.listType || localStorage.getItem('listType');

    const classListDescriptionRef = useRef();
    const classListNameRef = useRef();
    const classNumberRef = useRef([]);
    const classNameRef = useRef([]);
    const classDescriptionRef = useRef([]);
    const [isHovered, setIsHovered] = useState(false);
    const [addedRows, setAddedRows] = useState([]);
    const [classChanges, setClassChanges] = useState(false);    
    const [listUpdates, setListUpdates] = useState([]);
    const [listInfo, setListInfo] = useState([]);

    const deleteClassEntry = (classListDataId) => {
        const deleteForm = {
            classListDataId: classListDataId,
        }
        Ajax.request({
            url:"/JudgeAutos/deleteClassEntry",
            jsonData: deleteForm,
            success:function(reply) {
                swal.fire({
                    title: "Class Deleted",
                    text: reply.data,
                    icon: "success",
                    timer: 1000,
                    showConfirmButton: false,
                    didClose:(() => {
                        localStorage.setItem("userid", props.userInformation.userId);
                        localStorage.setItem("listId", listId);
                        localStorage.setItem("listType", listType);

                        window.location.reload();
                    })
                })
            },
            failures:function(reply) {
                swal.fire({
                    title: "Failed to load list",
                    text: {reply},
                    icon: "error"
                })
            }
        });
    }

    const handleAddRow = () => {
        setAddedRows([...addedRows, { classNumber: '', className: '', classDescription: '' }]);
    }

    const handleClassAdditions = (index, event) => {
        const { id, value } = event.target;
        const updatedRows = addedRows.map((row, rowIndex) => 
            rowIndex === index ? { ...row, [id]: value } : row
        );
        setAddedRows(updatedRows);
    };

    const handleClassChanges = (change, e, index) => {
        const id = change.target.id;
        const value = change.target.value;
        
        setClassChanges((previousChanges) => {
            const updatedChanges = { ...previousChanges };

            if (!updatedChanges[e]) {
                updatedChanges[e] = {};
            }
            
            updatedChanges[e][id] = value;

            if (!updatedChanges[e]["classListDataId"]) {
                updatedChanges[e]["classListDataId"] = e;
            }

            const allRefs = { classNumber: classNumberRef[index]["value"], className: classNameRef[index]["value"], classDescription: classDescriptionRef[index]["value"] };
            
            Object.entries(allRefs).forEach(([inputId, ref]) => {
                const inputElement = ref;
                
                if (inputId !== id && !updatedChanges[e][inputId]) {
                    if (inputElement) {
                        updatedChanges[e][inputId] = inputElement;
                    } else {
                        updatedChanges[e][inputId] = null;
                    }
                }
            });
            
            return updatedChanges;
        });
    };

    const handleListHover = (index) => {
        setIsHovered(index);
    }
    const handleListUnhover = () => {
        setIsHovered(false);
    }

    const handleListUpdate = (change) => {
        const id = change.target.id;
        const value = change.target.value;

        setListUpdates((listUpdate) => {
            const changes = {...listUpdate};

            if (!changes["listId"]) {
                changes["listId"] = listId;
            }

            if (id.toLowerCase() !== "classlistname" && !changes[id]) {
                changes["classListName"] = classListNameRef.current.value;
            }

            if (id.toLowerCase() !== "classlistdescription" && !changes[id]) {
                changes["classListDescription"] = classListDescriptionRef.current.value;
            }

            changes[id] = value;

            return changes;
        });

        return;
    }

    const saveListChanges = () => {
        const updateForm = {
            classAdditions: addedRows,
            classChanges: classChanges
        }
        const classAdditionNumbers = updateForm.classAdditions.map(item => item.classNumber);
        const classChangeNumbers = Object.values(updateForm.classChanges).map(item => item.classNumber);
        const allClassNumbers = [...classAdditionNumbers, ...classChangeNumbers];
        const numberCounts = allClassNumbers.reduce((acc, num) => {
            acc[num] = (acc[num] || 0) + 1;
            return acc;
        }, {});
        const duplicateNumbers = Object.keys(numberCounts).filter(num => numberCounts[num] > 1);

        if (duplicateNumbers.length > 0) {
            swal.fire({
                title: `Found ${duplicateNumbers.length} duplicate(s)!`,
                html:`
                    <h1>Duplicate Numbers Found:</h1>
                    <br />
                        ${duplicateNumbers.map(number => `<li>${number}</li>`).join('')}
                `
            })
        } else {
            Ajax.request({
                url:"/JudgeAutos/classListChanges",
                jsonData: {
                    classAdditions: updateForm.classAdditions,
                    classChanges: updateForm.classChanges,
                    listId: listId
                },
                success:function(reply) {
                    swal.fire({
                        title: "Saved Changes",
                        text: reply.data.updates && reply.data.additions ?
                            `${reply.data.updates} and ${reply.data.additions}` :
                            `${reply.data}!`,
                        icon: "success",
                        didClose:(() => {
                            localStorage.setItem("userid", props.userInformation.userId);
                            localStorage.setItem("listId", listId);
                            localStorage.setItem("listType", listType);

                            window.location.reload();
                        })
                    })
                },
                failures:function(reply) {
                    swal.fire({
                        title: "Failed to update list",
                        text: {reply},
                        icon: "error"
                    })
                }
            });
        }
    }

    const updateClassList = () => {
        Ajax.request({
            url:"/JudgeAutos/updateClassList",
            jsonData: listUpdates,
            success:function(reply) {
                swal.fire({
                    title: "Class List Updated",
                    text: reply.data,
                    icon: "success",
                    didClose:(() => {
                        localStorage.setItem("userid", props.userInformation.userId);
                        localStorage.setItem("listId", listId);
                        localStorage.setItem("listType", listType);

                        window.location.reload();
                    })
                })
            },
            failures:function(reply) {
                swal.fire({
                    title: "Failed to update list",
                    text: reply.data,
                    icon: "error"
                })
            }
        });
    }

    // WORK AROUND FOR NEXTUI ISSUE 1968
    const dummyKeyboardDelegate = Object.fromEntries(
        [
          "getKeyBelow",
          "getKeyAbove",
          "getKeyLeftOf",
          "getKeyRightOf",
          "getKeyPageBelow",
          "getKeyPageAbove",
          "getFirstKey",
          "getLastKey",
          // HAVE TO ignore this one
          // "getKeyForSearch"
        ].map((name) => [name, () => null]),
      );

    return(
        <div className="flex-col text-center">
            {listInfo.list && (
                <div>
                    <h1 className="text-5xl">{listInfo.list.classListName}</h1>
                    <Spacer y={2} />
                    <h2 className="text-4xl">Class List</h2>
                </div>
            )}
            <Spacer y={10} />
            {listInfo.list && (
                <div>
                    <div className="flex justify-between gap-6">
                        <Form className="w-full flex-grow">
                            <Input
                                key="classListName"
                                id="classListName"
                                label="Class Name"
                                ref={classListNameRef}
                                defaultValue={listInfo.list.classListName}
                                onChange={(change) => handleListUpdate(change)}
                                variant="bordered"
                            />
                        </Form>
                        <Form className="w-full flex-grow">
                            <Input
                                key="classListDescription"
                                id="classListDescription"
                                label="Class Description"
                                ref={classListDescriptionRef}
                                defaultValue={listInfo.list.classListDescription}
                                onChange={(change) => handleListUpdate(change)}
                                variant="bordered"
                            />
                        </Form>
                        <Form className="w-full flex-grow">
                            <Input
                                isReadOnly
                                key="classListDate"
                                id="classListDate"
                                label="Date Created"
                                defaultValue={listInfo.list.dateCreated.split(" ")[0]}
                                variant="flat"
                            />
                        </Form>
                    </div>
                    <Spacer y={3} />
                    {Object.keys(listUpdates).length > 0 && (
                        <Button
                            onClick={updateClassList}
                            color="primary"
                        >
                            Update
                        </Button>
                    )}
                </div>
            )}

            <Spacer y={5} />
            <Divider />
            <Spacer y={5} />

            {listInfo.listData && (
                <Table
                    isStriped
                    bottomContent={
                        <div className="flex justify-center">
                            <Button
                            variant="light"
                            className="w-1/8"
                            onClick={handleAddRow}>
                                <PlusCircle_8_Blue />
                            </Button>
                        </div>
                    }
                    keyboardDelegate={dummyKeyboardDelegate}
                    aria-label="Event registrations"
                >
                    <TableHeader>
                        <TableColumn>Number</TableColumn>
                        <TableColumn>Name</TableColumn>
                        <TableColumn>Description</TableColumn>
                    </TableHeader>
                    <TableBody>
                        {listInfo.listData.map((data, index) => (
                            <TableRow
                                key={data.classListDataId}
                                onChange={(e) => handleClassChanges(e, data.classListDataId, index)}
                                onMouseEnter={() => handleListHover(index)}
                                onMouseLeave={handleListUnhover}
                            >
                                <TableCell key={`classNumber${index}`}>
                                    <Input
                                        key={index}
                                        id="classNumber"
                                        ref={(number) => classNumberRef[index] = number}
                                        type="number"
                                        color="primary"
                                        variant="underlined"
                                        defaultValue={data.classNumber}
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell key={`className${index}`}>
                                    <Input
                                        key={index}
                                        id="className"
                                        ref={(name) => classNameRef[index] = name}
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        defaultValue={data.className}
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                    />
                                </TableCell>
                                <TableCell key={`classDescription${index}`} >
                                    <Input
                                        key={index}
                                        id="classDescription"
                                        ref={(desc) => classDescriptionRef[index] = desc}
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        defaultValue={data.classDescription}
                                        classNames={{input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"]}}
                                        endContent={ 
                                            <div className={`transition-opacity duration-200 ${isHovered === index ? 'opacity-100' : 'opacity-0'}`}>
                                                <button onClick={() => deleteClassEntry(data.classListDataId)}>
                                                    <Trash_8_Red />
                                                </button>
                                            </div>
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        {addedRows.map((row, index) => (
                            <TableRow>
                                <TableCell key={`classNumber${index}`}>
                                    <Input
                                        isRequired
                                        id="classNumber"
                                        type="number"
                                        inputMode="tel"
                                        color="primary"
                                        variant="underlined"
                                        value={row.classNumber}
                                        onChange={(event) => handleClassAdditions(index, event)}
                                        classNames={{ input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"] }}
                                    />
                                </TableCell>
                                <TableCell key={`className${index}`}>
                                    <Input
                                        isRequired
                                        id="className"
                                        placeholder="Enter description"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        value={row.className}
                                        onChange={(event) => handleClassAdditions(index, event)}
                                        classNames={{ input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"] }}
                                    />
                                </TableCell>
                                <TableCell key={`classDescription${index}`}>
                                    <Input
                                        id="classDescription"
                                        placeholder="Enter description"
                                        type="text"
                                        color="primary"
                                        variant="underlined"
                                        value={row.classDescription}
                                        onChange={(event) => handleClassAdditions(index, event)}
                                        classNames={{ input: "text-center bg-transparent", inputWrapper: ["border-0", "shadow-none"] }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
            <Spacer y={4} />
            <div>
                {(addedRows.length > 0 || classChanges) && (
                    <Button
                        color="primary"
                        onClick={saveListChanges}
                    >
                        Save
                    </Button>
                )}
            </div>
        </div>
    );
}