import React, {
    useEffect,
 } from "react";
import { 
    Button,
    Input,
    Link,
    Spacer
} from "@nextui-org/react";
import swal from "sweetalert2";
import Ajax from "../inc/js/Ajax";


export default function Home(props) {

    const [isVisible, setIsVisible] = React.useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);

    useEffect(() => {
        if(props.userInformation.loggedIn === true) {
            document.location.href = "/MyAccount";
        }
    }, [props]);

    function login() {
        const form = {
            username:document.getElementById("username").value,
            password:document.getElementById("password").value,
        };

        Ajax.request({
            url:"/JudgeAutos/authenticate",
            jsonData:form,
            success:function(reply) {
                if(reply.login) {
                    document.location.href = "/";
                } else {
                    { swal.fire({
                        title:"Oops!",
                        text:"Incorrect username or password.",
                        icon:"error",
                        timer: 1500
                    }); }
                }
                document.getElementById("username").value = "";
                document.getElementById("password").value = "";
                document.getElementById("username").focus();
            }
        });
    }

    function createAccount() {
        document.location.href = "/CreateAccount"
    }

    function handleKeyDown(event) {
        if(event.key === "Enter") {
            login();
        }
    }

    return(
        <div className="w-full sm:w-1/2 text-center">
            <div className="grid grid-cols-1 gap-4 m-4 p-5 border-solid border-2 rounded-2xl">
                <h1 className="text-5xl">Login</h1>
                <Spacer y={4} />
                <Input
                    id="username"
                    label="Email"
                    variant="bordered"
                    placeholder="address@domain.com"
                    type={"email"}
                    required={true}
                />
                <Input
                    id="password"
                    label="Password"
                    type={isVisible ? "text" : "password"}
                    required={true}
                    onKeyDown={handleKeyDown}
                    variant="bordered"
                    placeholder="P@zZw0rD!"
                    />
                <Link href="/ForgotPassword" className="place-content-center">Forgot password?</Link>
                <Button
                color="primary" 
                variant="solid"
                className="mx-auto"
                onClick={login}>
                    Login
                </Button>
                <Button
                color="primary"
                variant="bordered"
                className="mx-auto"
                onClick={createAccount}>
                    Create Account
                </Button>
            </div>
        </div>
    );
}