import React, {
    useEffect
} from "react";
import {
    Spacer
} from "@nextui-org/react";
import swal from "sweetalert2";

export default function FAQ() {

    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Frequently Asked Questions</h1>
            <Spacer y={10} />
            
        </div>
    );
}