import React, {useEffect} from "react";
import {
    Divider,
    Link,
    Spacer
} from "@nextui-org/react";

export default function GettingStarted() {
    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Getting Started</h1>
            <Divider className="my-10 w-3/4 mx-auto" />
            <div className="w-1/2 mx-auto text-lg">
                <h2 className="text-3xl my-5">1: Setup an Account</h2>
                <div>
                    <p>
                        First setup your account by visiting our <Link href="/CreateAccount" underline="always" size="lg">Create Account</Link> page.
                    </p>
                </div>
                <Divider className="my-10 w-3/4 mx-auto" />
                <h2 className="text-3xl my-5">2: Purchase a Package</h2>
                <div>
                    <p>
                        After you verify your account via email, <Link href="/Login" underline="always" size="lg">Login</Link> and select a package at our <Link href="/Pricing" underline="always" size="lg">Pricing</Link> page that fits your event's needs.
                    </p>
                </div>
                <Divider className="my-10 w-3/4 mx-auto" />
                <h2 className="text-3xl my-5">3: Claim your Event</h2>
                <div>
                    <p>
                        Once you have purchased a package, claim the event in <Link href="/Events?Unclaimed" underline="always" size="lg">Unclaimed</Link> to set the event Name, Date, Rainout Date, and Location.
                    </p>
                </div>
                <Divider className="my-10 w-3/4 mx-auto" />
                <h2 className="text-3xl my-5">4: Setup the Event</h2>
                <div>
                    <p>
                        Go to your event on your <Link href="/Events?upcoming" underline="always" size="lg">Events</Link> page, and select it.  In the event, you can invite registrars and judges, select Custom Forms or Class Lists, and change any details of the Event as needed!
                    </p>
                </div>
                <Divider className="my-10 w-3/4 mx-auto" />
                <h2 className="text-3xl my-5">5: Have the Event</h2>
                <div>
                    <p>
                        Once you have the Event setup with registrars, judges, and your forms (if applicable) you are ready for your event!  You can see upcoming events on your <Link href="/Events?upcoming" underline="always" size="lg">Events</Link> page.
                    </p>
                </div>
            </div>
            <Divider className="mt-10 mb-20 w-3/4 mx-auto" />
            <h3 className="text-5xl my-5">More Information</h3>
            <p className="text-lg">
                Need more information?<br />View our <Link href="/Docs" underline="always" size="lg">Documentation</Link> on everything JudgeAutos or take a look at our <Link href="/FAQ" underline="always" size="lg">FAQ</Link>!
            </p>
        </div>
    );
}