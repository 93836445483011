import React, {
  useEffect
} from "react";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import "./inc/css/loader.css";
import Ajax from "./inc/js/Ajax";
import {
  Spacer,
  Spinner
} from "@nextui-org/react";

import About from "./components/About";
import AccountCreated from "./components/AccountCreated";
import Contact from './components/Contact';
import CreateAccount from './components/CreateAccount';
import ClaimEvent from './components/ClaimEvent';
import Docs from './components/Docs';
import Error404 from "./components/Error404";
import Event from './components/Event';
import Events from './components/Events';
import FAQ from "./components/FAQ";
import Feedback from "./components/Feedback";
import Footer from "./components/Footer";
import ForgotPassword from './components/ForgotPassword';
import Forms from "./components/Forms";
import GettingStarted from "./components/GettingStarted";
import Header from './components/Header';
import Home from './components/Home';
import Judging from './components/Judging';
import List from './components/List';
import Login from './components/Login';
import MyAccount from './components/MyAccount';
import Pricing from './components/Pricing';
import PrivacyPolicy from './components/PrivacyPolicy';
import Registration from './components/Registration';
import ResetPassword from './components/ResetPassword';
import Results from './components/Results';
import Verified from "./components/Verified";

export default function App() {
  const [userInformation, setUserInformation] = React.useState({});

  useEffect(() => {
    Ajax.request({
        url:"/JudgeAutos/checkIfLoggedIn",
        method:"GET",
        success:function(reply) {
            setUserInformation(reply.data.userInformation);            
        }
    });
  }, []);
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home userInformation = {userInformation} />,
      errorElement: <Error404 />,
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/About",
      element: <About />,
    },
    {
      path: "/AccountCreated",
      element: <AccountCreated />,
    },
    {
      path: "/Contact",
      element: <Contact />,
    },
    {
      path: "/CreateAccount",
      element: <CreateAccount userInformation = {userInformation} />,
    },
    {
      path: "/ClaimEvent",
      element: <ClaimEvent userInformation = {userInformation} />,
    },
    {
      path: "/Docs",
      element: <Docs userInformation = {userInformation} />,
    },
    {
      path: "/Event",
      element: <Event userInformation = {userInformation}/>,
    },
    {
      path: "/Events",
      element: <Events userInformation = {userInformation} />,
    },
    {
      path: "/FAQ",
      element: <FAQ />,
    },
    {
      path: "/Feedback",
      element: <Feedback userInformation = {userInformation} />,
    },
    {
      path: "/Footer",
      element: <Footer />,
    },
    {
      path: "/Forms",
      element: <Forms userInformation = {userInformation} />,
    },
    {
      path: "/GettingStarted",
      element: <GettingStarted />,
    },
    {
      path: "/Judging",
      element: <Judging />,
    },
    {
      path: "/List",
      element: <List userInformation = {userInformation} />,
    },
    {
      path: "/Login",
      element: <Login userInformation = {userInformation} />,
    },
    {
      path: "/MyAccount",
      element: <MyAccount userInformation = {userInformation} />,
    },
    {
      path: "/Pricing",
      element: <Pricing userInformation = {userInformation} />,
    },
    {
      path: "/PrivacyPolicy",
      element: <PrivacyPolicy userInformation = {userInformation} />,
    },
    {
      path: "/Registration",
      element: <Registration />,
    },
    {
      path: "/ResetPassword",
      element: <ResetPassword />,
    },
    {
      path: "/Results",
      element: <Results userInformation = {userInformation} />,
    },
    {
      path: "/ForgotPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/Verified",
      element: <Verified />,
    },
  ]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-grow">
        <div className="hidden sm:block sm:w-1/5 sm:bg-gray-100"></div>
        <div className="flex-grow flex flex-col min-h-screen">
          <Header userInformation={userInformation} />
          <div id="loading" className="flex-shrink-0">
            <div id="loading-image">
              <Spinner />
            </div>
          </div>
          <Spacer y={10} />
          <div className="flex-grow flex mx-auto justify-center w-11/12 sm:w-3/4 mb-10">
            <RouterProvider router={router} />
          </div>
          <div className="w-full sm:w-11/12 mx-auto mt-auto">
            <Footer />
          </div>
        </div>
        <div className="hidden sm:block sm:w-1/5 sm:bg-gray-100"></div>
      </div>
    </div>
  );
  

//   return (
//     <main className="light flex min-h-screen bg-white-800">
//         <div className="invisible sm:visible sm:w-1/5 sm:bg-gray-100 sm:min-h-screen"></div>
//             <div className="flex-grow min-h-screen flex flex-col">
//                 <Header userInformation={userInformation} />
//                 <div id="loading" className="flex-shrink-0">
//                     <div id="loading-image">
//                     <Spinner />
//                     </div>
//                 </div>
//                 <Spacer y={10} />
//                 <div className="flex mx-auto justify-center w-3/4 mb-10">
//                     <RouterProvider router={router} />
//                 </div>
//                 <div className="w-11/12 mx-auto">
//                     <Footer />
//                 </div>
//             </div>
//         <div className="invisible sm:visible sm:w-1/5 sm:bg-gray-100 sm:min-h-screen"></div>
//     </main>
//   );
}