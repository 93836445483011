import React, {
    useEffect,
    useState
} from "react";
import { 
    useLocation,
    useNavigate
} from 'react-router-dom';
import { 
    Link,
    Spacer,
    Tab,
    Tabs,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell
} from "@nextui-org/react";
import "../inc/css/loader.css";
import swal from 'sweetalert2';
import Ajax from "../inc/js/Ajax";

export default function MyEvents(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [events, setEvents] = useState([]);
    const [defaultTab, setDefaultTab] = useState(null);
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (props.userInformation.loggedIn === false) {
            document.location.href = "/Login";
        }
    }, [props]);

    useEffect(() => {
        setDefaultTab(searchParams.get('tab'));
    }, [location.search])

    useEffect(() => {
        Ajax.request({
            url:"/JudgeAutos/readEvents",
            success:function(reply) {
                if(reply.data) {
                    setEvents(reply.data);
                }
            }
        });
    }, []);

    function claimEvent(salesId, productName, formType){
        navigate('/ClaimEvent', {
            state:{
                salesId,
                productName,
                formType
            }
        });
    }

    function openEvent(eventId, eventName, eventStatus){
        navigate('/Event', {
            state: {
                eventId,
                eventName,
                eventStatus
            } 
        });
    }

    return(
        <div className="flex-col text-center">
            <h1 className="text-5xl">Events</h1>
            <Spacer y={10} />
            <Tabs defaultSelectedKey={defaultTab}>
                <Tab key="active" title="Active">
                    {events.active?.length > 0 ? (
                        <Table 
                        isStriped
                        aria-label="My events table"
                        onRowAction={
                            (key) => openEvent(
                                events.active[key].eventId,
                                events.active[key].eventName,
                                events.active[key].status
                            )
                        }
                        // onSortChange={list.sort}
                        >
                            <TableHeader>
                                <TableColumn >Event</TableColumn>
                                <TableColumn >Location</TableColumn>
                                <TableColumn >Registrars</TableColumn>
                                <TableColumn >Judges</TableColumn>
                                <TableColumn >Registrations</TableColumn>
                                <TableColumn >Classes</TableColumn>
                                <TableColumn >Forms</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {events.active.map((item, index) => (
                                    <TableRow key={index} style={{ cursor: 'pointer'}}>
                                        <TableCell>{item.eventName}</TableCell>
                                        <TableCell>{item.eventLocation}</TableCell>
                                        <TableCell>{item.registrarLimit === 999999999 ? "Unlimited": item.registrarLimit}</TableCell>
                                        <TableCell>{item.judgeLimit === 999999999 ? "Unlimited": item.judgeLimit}</TableCell>
                                        <TableCell>{item.registrationLimit === 999999999 ? "Unlimited": item.registrationLimit}</TableCell>
                                        <TableCell>{item.classLimit === 999999999 ? "Unlimited": item.classLimit}</TableCell>
                                        <TableCell>{item.forms.charAt(0).toUpperCase() + item.forms.slice(1)}</TableCell>
                                    </TableRow>
                                ))};
                            </TableBody>
                        </Table>
                    ) : (
                        <p>
                            Oh no, you have no Active events!<br />Buy one <Link href="/Pricing">here</Link>!
                        </p>
                    )}
                </Tab>
                <Tab key="upcoming" title="Upcoming">
                    {events.upcoming?.length > 0 ? (
                        <Table 
                        isStriped
                        aria-label="My events table"
                        onRowAction={
                            (key) => openEvent(
                                events.upcoming[key].eventId,
                                events.upcoming[key].eventName,
                                events.upcoming[key].status
                            )
                        }
                        // onSortChange={list.sort}
                        >
                            <TableHeader>
                                <TableColumn >Event</TableColumn>
                                <TableColumn >Date</TableColumn>
                                <TableColumn >Location</TableColumn>
                                <TableColumn >Registrars</TableColumn>
                                <TableColumn >Judges</TableColumn>
                                <TableColumn >Registrations</TableColumn>
                                <TableColumn >Classes</TableColumn>
                                <TableColumn >Forms</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {events.upcoming.map((item, index) => (
                                    <TableRow key={index} style={{ cursor: 'pointer'}}>
                                        <TableCell>{item.eventName}</TableCell>
                                        <TableCell>{item.eventDate}</TableCell>
                                        <TableCell>{item.eventLocation}</TableCell>
                                        <TableCell>{item.registrarLimit === 999999999 ? "Unlimited": item.registrarLimit}</TableCell>
                                        <TableCell>{item.judgeLimit === 999999999 ? "Unlimited": item.judgeLimit}</TableCell>
                                        <TableCell>{item.registrationLimit === 999999999 ? "Unlimited": item.registrationLimit}</TableCell>
                                        <TableCell>{item.classLimit === 999999999 ? "Unlimited": item.classLimit}</TableCell>
                                        <TableCell>{item.forms.charAt(0).toUpperCase() + item.forms.slice(1)}</TableCell>
                                    </TableRow>
                                ))};
                            </TableBody>
                        </Table>
                    ) : (
                        <p>
                            Oh no, you have no events!<br />Buy one <Link href="/Pricing">here</Link>!
                        </p>
                    )}
                </Tab>
                <Tab key="completed" title="Completed">
                    {events.completed?.length > 0 ? (
                        <Table 
                        isStriped
                        aria-label="My events table"
                        onRowAction={
                            (key) => openEvent(
                                events.completed[key].eventId,
                                events.completed[key].eventName,
                                events.completed[key].status
                            )
                        }
                        // onSortChange={list.sort}
                        >
                            <TableHeader>
                                <TableColumn >Event</TableColumn>
                                <TableColumn >Event Date</TableColumn>
                                <TableColumn >Location</TableColumn>
                                <TableColumn >Package</TableColumn>
                                <TableColumn >Sale Date</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {events.completed.map((item, index) => (
                                    <TableRow key={index} style={{ cursor: 'pointer'}}>
                                        <TableCell>{item.eventName}</TableCell>
                                        <TableCell>{item.eventDate}</TableCell>
                                        <TableCell>{item.eventLocation}</TableCell>
                                        <TableCell>{item.productName}</TableCell>
                                        <TableCell>{item.saleDate}</TableCell>
                                    </TableRow>
                                ))};
                            </TableBody>
                        </Table>
                    ) : (
                        <p>
                            Oh no, you have not completed any events!<br />Buy one <Link href="/Pricing">here</Link>!
                        </p>
                    )}
                </Tab>
                <Tab key="unclaimed" title="Unclaimed">
                    {events.unclaimed?.length > 0 ? (
                        <Table 
                        isStriped
                        aria-label="My events table"
                        onRowAction={
                            (key) => claimEvent(
                                events.unclaimed[key].salesId,
                                events.unclaimed[key].productName,
                                events.unclaimed[key].forms
                            )
                        }
                        // onSortChange={list.sort}
                        >
                            <TableHeader>
                                <TableColumn >Sales Id</TableColumn>
                                <TableColumn >Purchase Date</TableColumn>
                                <TableColumn >Package</TableColumn>
                                <TableColumn >Registrars</TableColumn>
                                <TableColumn >Judges</TableColumn>
                                <TableColumn >Registrations </TableColumn>
                                <TableColumn >Classes</TableColumn>
                                <TableColumn >Forms</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {events.unclaimed.map((item, index) => (
                                    <TableRow key={index} style={{ cursor: 'pointer'}}>
                                        <TableCell>{item.salesId}</TableCell>
                                        <TableCell>{item.saleDate}</TableCell>
                                        <TableCell>{item.productName}</TableCell>
                                        <TableCell>{item.registrarLimit === 999999999 ? "Unlimited": item.registrarLimit}</TableCell>
                                        <TableCell>{item.judgeLimit === 999999999 ? "Unlimited": item.judgeLimit}</TableCell>
                                        <TableCell>{item.registrationLimit === 999999999 ? "Unlimited": item.registrationLimit}</TableCell>
                                        <TableCell>{item.classLimit === 999999999 ? "Unlimited": item.classLimit}</TableCell>
                                        <TableCell>{item.forms.charAt(0).toUpperCase() + item.forms.slice(1)}</TableCell>
                                    </TableRow>
                                ))};
                            </TableBody>
                        </Table>
                    ) : (
                        <p>
                            Oh no, you have no available events!<br />Buy one <Link href="/Pricing">here</Link>!
                        </p>
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}