import React from 'react';
import { Spacer } from "@nextui-org/react";

export default function AccountCreated() {
    return (
        <div className="h-full">
          <div className="text-center">
            <h1 className="text-5xl">Account Created!</h1>
            <Spacer y={10} />
            <div className="flex flex-col justify-center items-center space-y-4">
              <h2 className="text-3xl">Please verify your email</h2>
              <p className="sm:w-1/2">
                A link to unlock your account has been sent to your email address! If you do not see the email, check your spam and junk folders. <br /><br />
                Thank you for choosing Judge Autos!
              </p>
            </div>
          </div>
        </div>
      );
      
}