import React, {useState} from "react";
import Ajax from "../inc/js/Ajax";
import {
    Avatar,
    Button,
    Link,
    Navbar, 
    NavbarBrand, 
    NavbarContent, 
    NavbarItem, 
    NavbarMenuToggle,
    NavbarMenu,
    NavbarMenuItem,
    mapPropsVariants,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger
  } from "@nextui-org/react";
  import swal from "sweetalert2";
  import {
    Menu_6_Black,
    Menu_8_Black,
    Menu_alt_3_6_Black
  } from "../inc/images/svgs";

  export default function Header(props) {
    function logout() {
        Ajax.request({
            url:"/JudgeAutos/logout",
            success:function() {
                document.location.href = "/";
            }
        });
    }

    const [state, setState] = useState({
        openSideMenu:false,
        openMyAccountMenu:false,
        myAccountMenuAnchor:null
    });

    return(
        <Navbar shouldHideOnScroll isBordered>
            <NavbarBrand className="sm:flex sm:justify-center">
                <Link href="/" color="foreground">
                    <h1 className="text-3xl font-black">JudgeAutos</h1>
                </Link>
            </NavbarBrand>
            <NavbarContent className="hidden sm:flex gap-4" justify="center">
                <NavbarItem>
                <Link className="text-lg" color="foreground" href="/">
                    Home
                </Link>
                </NavbarItem>
                <NavbarItem>
                <Link className="text-lg" color="foreground" href="/About">
                    About
                </Link>
                </NavbarItem>
                <NavbarItem>
                <Link className="text-lg" color="foreground" href="/Pricing">
                    Pricing
                </Link>
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem className="">
                    {!props.userInformation.loggedIn ?(
                        <div>
                            <Link className="invisible sm:visible text-lg" color="foreground" href="/Login">
                                Login
                            </Link>
                        <Dropdown className="visible sm:invisible">
                            <DropdownTrigger>
                                <Button
                                    className="visible sm:invisible"
                                    variant="light"
                                >
                                    <Menu_8_Black />
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu aria-label="My account menu">
                                <DropdownItem key="login" href="/Login" textValue="Login">
                                    <p className="text-xl">Login</p>
                                </DropdownItem>
                                <DropdownItem key="about" href="/About" textValue="About">
                                    <p className="text-xl">About</p>
                                </DropdownItem>
                                <DropdownItem key="pricing" href="/Pricing" textValue="Pricing">
                                    <p className="text-xl">Pricing</p>
                                </DropdownItem>
                                <DropdownItem key="contact" href="/Contact" textValue="Contact Us">
                                    <p className="text-xl">Contact Us</p>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                   </div>
                    ) : (
                        <Dropdown>
                            <DropdownTrigger>
                                {props.userInformation.avatar ? (
                                    <Avatar src={props.userInformation.avatar} style={{
                                        cursor: 'pointer'
                                    }} />
                                ) : (
                                    <Avatar name={props.userInformation.firstName} style={{
                                        cursor: 'pointer'
                                    }} />
                                )}
                            </DropdownTrigger>
                            <DropdownMenu aria-label="My account menu">
                                <DropdownItem key="myAccount" href="/MyAccount" textValue="My Account">
                                    My Account
                                </DropdownItem>
                                <DropdownItem key="Events" href="/Events" textValue="My Events">
                                    My Events
                                </DropdownItem>
                                <DropdownItem key="contact" href="/Contact" textValue="Contact Us">
                                    Contact Us
                                </DropdownItem>
                                <DropdownItem key="feedback" href="/Feedback" textValue="Feedback">
                                    Feedback
                                </DropdownItem>
                                <DropdownItem onClick={logout} color="danger" textValue="Logout">
                                    Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    );
}