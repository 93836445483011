import React, {useEffect, useState} from 'react';
import {Form} from "react-router-dom";
import {
    Button,
    Input,
    Spacer
} from "@nextui-org/react";
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert2";

export default function ResetPassword() {
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [code, setCode] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        const code = params.code;

        if (code !== null) {
            Ajax.request({
                url:"/JudgeAutos/validatePasswordResetCode",
                jsonData:{code:code},
                success:function(reply) {
                    setCode(code);
                    setEmail(reply.data.email);
                },
                failure:function(reply) {
                    swal.fire({
                        title:"Oops!",
                        text: JSON.stringify(reply),
                        icon:"error",
                        showConfirmButton: false,
                        timer: 3000,
                        didClose: () => {
                            document.location.href = "/Login"
                        }
                    })
                }
            })
        } else {
            document.location.href = "/Login"
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const isPasswordValid = newPassword.length >= 9;

        if(!isPasswordValid) {
            swal.fire({
                title:"Invalid password",
                text:"Password must be at least 9 characters!",
                icon:"error",
                timer: 1500
            });
            setIsPasswordValid(false);
            return;
        }

        setIsPasswordValid(true);

        Ajax.request({
            url:"/JudgeAutos/resetPassword",
            jsonData:{code:code, newPassword:newPassword},
            success:function() {
                swal.fire({
                    title:"Success!",
                    text: "Password has been reset",
                    icon:"success",
                    showConfirmButton: false,
                    timer: 3000,
                    didClose: () => {
                        document.location.href = "/Login"
                    }
                })
            }
        });
    };

    return (
        <div className="sm:w-1/2 text-center">      
            <div className="grid grid-cols-1 gap-4 m-4 p-5 border-solid border-2 rounded-2xl">
                <h1 className="text-5xl">Reset Password</h1>
                <Spacer y={5} />
                <Form onSubmit={handleSubmit}>
                    <Input
                        isReadOnly
                        id="email"
                        label="Email"
                        variant="bordered"
                        type={"email"}
                        placeholder={email}
                    />
                    <Spacer y={1} />
                    <Input
                        id="password"
                        label="New Password"
                        type={isVisible ? "text" : "password"}
                        required={true}
                        isInvalid={!isPasswordValid}
                        errorMessage={!isPasswordValid ? "Password must be at least 9 characters long" : ""}
                        variant="bordered"
                        placeholder="New P@zZw0rD"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <Spacer y={1} />
                    <Button
                        variant="solid"
                        type="submit"
                        color="primary"
                    >
                        Update Password
                    </Button>
                </Form>
            </div>
        </div>
    );
}